import React, { useState } from 'react'
// import { navigate } from '@reach/router'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, GreyBtn, md, GreenBtn } from '../common'
import Modal from '../components/modal'
import Contact from '../components/contactForm'
import { Label } from '../components/input'

export default ({ data, title }) => {
  const items = data.postgres.allProductsItems.edges

  return(
    <div css={`padding:2rem;text-align:center;`}>
    <h1 css={`color:${colors.green};text-transform:uppercase;`}>{title}</h1>
    <Link to="/products">
      <GreyBtn>View More Products</GreyBtn>
    </Link>
    <br />
    <br />
    <Wrap>
      {items.map((item, index) => {
        const i = item.node
        const price = i.price ? i.price : 'AVAILABLE UPON REQUEST'
        const [ show, setShow ] = useState(false)
        const [ zoom, setZoom ] = useState(false)
        const [ product, setProduct ] = useState('')
        const [ hash, setHash ] = useState('')
        const [inquireHash, setInquireHash ] = useState('')

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        })

        function handleClick() {
          setInquireHash(`${i.sku}_inquire`)
          if (typeof window !== "undefined") {
            window.scrollTo(0,0)
            window.location.hash = inquireHash
          }
          setShow(!show)
          setProduct(i)
        }

        function handleZoomClick() {
          setZoom(!zoom)
          setProduct(i)
          setHash(`${i.sku}_product`)
          if (typeof window !== "undefined") {
            window.scrollTo(0,0)
            window.location.hash = hash
          }

        }

        return  <Card key={index} id={`${i.sku}_product`} >
                  <div id={`${i.sku}_inquire`}>
                    <img css={`cursor:zoom-in;${md}{cursor:auto;}`} onClick={handleZoomClick} src={i.image} alt={i.title} onError={(e)=>{e.target.onerror = null; e.target.src="https://s3.amazonaws.com/underwoods/noitem.gif"}} />
                  </div>
                  <p css={`font-size:2rem;color:${colors.greyDarker};`}>{i.title}</p>
                  <p css={`text-transform:uppercase;`}>{isNaN(Number(price)) ? price : formatter.format(price)}</p>
                  <p css={`color:${colors.green};margin:0;`}>{i.designer}</p>
                  <p css={`margin:.5rem;text-transform:uppercase;font-size:14px;`}>item number :{i.sku}</p>
                  <br />
                  {i.purchasable ? <GreyBtn
                      className="snipcart-add-item"
                      data-item-id={i.sku}
                      data-item-name={i.title}
                      data-item-image={i.image}
                      data-item-price={i.price}
                      data-item-url={`https://www.underwoodjewelers.com/products/specials`}
                      data-item-description={i.description}>
                          Add To Cart
                  </GreyBtn> : <GreenBtn onClick={handleClick}>Inquire about this item</GreenBtn>}
                  <br />
                  <Modal show={ show } toggle={ handleClick } mobile={ true } onClick={ () => { if (window !== "undefined") {window.location.hash = i.sku }}}>
                    <h2>Inquire About this {product.title}</h2>
                    <h3>ITEM NUMBER: {product.sku}</h3>
                    <p css={`padding:0 1rem 1rem 1rem;font-family:sans;`}>{product.description}</p>
                    <Contact>
                      <Label htmlFor="store preference">store preference</Label>
                      <select css={`margin: 5px 0;`} name="store preference">
                        <option default value="any">Any</option>
                        <option value="San Marco">San Marco</option>
                        <option value="Ponte Vedra Beach">Ponte Vedra Beach</option>
                        <option value="Avondale">Avondale</option>
                      </select>
                      <br />
                      <input css={`border:0;`} type="text" name="product name" value={product.title} readOnly hidden/>
                      <input css={`border:0;`} type="text" name="product sku" value={product.sku} readOnly hidden />
                      <input css={`border:0;`} type="text" name="product designer" value={product.designer} readOnly hidden />
                      <input css={`border:0;`} type="text" name="product description" value={product.description} readOnly hidden />
                      <input css={`border:0;`} type="text" name="gallery source" value={title} readOnly hidden />
                      <br />
                    </Contact>
                  </Modal>
                  <Modal show={ zoom } toggle={ handleZoomClick } sku={ i.sku } mobile={ false } >
                    <img css={`max-width:600px;margin-bottom:1rem;border:1px solid grey;`} src={product.image} alt={product.title} />
                    <p css={`padding:0 1rem 1rem 1rem;font-family:sans;`}>{product.description}</p>
                  </Modal>
                </Card>
      })}
    </Wrap>
    <br />
    <Link to="/products">
      <GreyBtn>View More Products</GreyBtn>
    </Link>
    <br />
    </div>
  )
}

const Card = styled.div`
  width: calc(25% -1rem);
  max-width: 350px;
  padding: .5rem 2rem;
  margin: 1rem .5rem;
  text-align:center;
  align-self:top;
  border: .5px solid rgba(0,0,0,.2);
  border-radius:5px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;

  img {
    width: 100%;
  }
  ${md} {
    align-self:center;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  margin: auto;

  ${md} {
    flex-direction:column;
  }
`
