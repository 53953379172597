import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import JoinUs from '../../sections/joinUs'
import Products from '../../sections/gallery-products'

export default ({ data }) => (
  <Layout>
    <SEO title={`Underwoods Estate`} />
    <Products data={data} title={`A selection from the Underwood's Estate Collection`}/>
    <JoinUs />
  </Layout>
)

export const EstateQuery = graphql`
  query EstateQuery {
    postgres {
      allProductsItems(first: 100, orderBy: CREATED_AT_DESC, condition: {designer: "Underwood's Estate"}) {
        edges{
        node {
          price
          title
          id
          image
          designer
          description
          purchasable
          sku
        }
      }
      }
    }
  }
`
